import React, { useEffect, useState } from "react";
import "./About.css";
import Header from "../Home/Header";
import "../Home/Header.css";
import "../Home/Hero.css";
import pic from "../Assets/images/jpg/67-a.jpg";
import "../Home/Menu";
import star from "../Assets/images/star.svg";
import msgIcon from "../Assets/images/web_2024-16.svg";
import valuesIcon from "../Assets/images/web_2024-17.svg";
import oneIcon from "../Assets/images/web_2024-05.svg";
import twoIcon from "../Assets/images/web_2024-09.svg";
import threeIcon from "../Assets/images/web_2024-08.svg";
import fourIcon from "../Assets/images/web_2024-07.svg";
import fiveIcon from "../Assets/images/web_2024-06.svg";
import sloganIcon from "../Assets/images/web_2024-18.svg";
import presidentIcon from "../Assets/images/web_2024-19.svg";
import Footer from "../Home/Footer";
import axios from "axios";

const About = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [websiteData, setWebsiteData] = useState("");

  const fetchWebsiteData = async () => {
    await axios
      .get("https://back-end.uniondiamondco.com/api/website-data")
      .then((res) => {
        setWebsiteData(res.data.data);
        console.log(websiteData);
      });
  };

  useEffect(() => {
    fetchWebsiteData();
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 0.1) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div>
      <div className={` ${isScrolled ? "noback" : "back"}`}></div>
      <Header />
      <div className="classs">
        <img className="mainimg" src={websiteData.who_image}></img>
      </div>
      <div className="back2"></div>
      <div className="who-us container">
        <p className="whoustitle"> مـن نـحــن</p>
      </div>
      <div className="break"></div>
      <div className="introduction container">
        <p>
          اتحاد الماسة للمقاولات تم تأسيسها في عام 2004 لتكون ضمن الشركات
          الوطنية الرائدة في مجال التطوير العقاري والاعمال الانشائية بمعايير
          جودة عالية وتقنيات حديثة تلبى كافة رغبات وطلبات السوف المحلى
        </p>
      </div>
      <div className="detailss container mt-2">
        <div className="p1">
          <div className="vision  ">
            <div className="vision-title">
              <p className="title">الرؤيـة</p>
            </div>
            <p className="text">
              التخطيط الناجح والتنفيذ الدقيق والأداء المبتكر والجودة العالية
              والمصداقية الكبيرة، هي معايير عملنا التي تعزز ريادتنا للخدمات
              المتكاملة في مجال التطوير العقاري.
            </p>
          </div>
          <div className="vision ">
            <div className="vision-title">
              <p className="title">الرسالة</p>
            </div>
            <p className="text">
              من أكبر الشركات التي تعمل بنشاط التطوير العقاري وان نقوم بناء مسكن
              عصري متميز يلبى جميع احتياجات الاسرة بأعلى معايير من الجودة في
              البناء والتشييد حسب الأنظمة الحديثة
            </p>
          </div>
        </div>

        <div className="vision ">
          <div className="vision-title">
            <p className="title">قيمنا</p>
          </div>
          <div className="logos">
            <div className="one  ">
              <img src={star} alt="" />
              <p className="pt-3 pe-2">التعاون والشراكة</p>
            </div>
            <div className="two">
              <img src={star} alt="" />

              <p className="pt-3 pe-2">الكفاءة والجودة</p>
            </div>
            <div className="three">
              <img src={star} alt="" />

              <p className="pt-3 pe-2"> الـتـحـــــدي</p>
            </div>
            <div className="four">
              <img src={star} alt="" />

              <p className="pt-3 pe-2"> الإبــــداع</p>
            </div>
            <div className="five">
              <img src={star} alt="" />

              <p className="pt-3 pe-2"> السـلامــة</p>
            </div>
          </div>
        </div>
        <div className="vision ">
          <div className="vision-title">
            <p className="title">شعارنا</p>
          </div>
          <div className="logos">
            <div className="slogan">
              <img src={star} alt="" />
              <p className="pt-3 pe-2">التميز</p>
            </div>
            <div className="slogan">
              <img src={star} alt="" />
              <p className="pt-3 pe-2">الجودة</p>
            </div>
            <div className="slogan">
              <img src={star} alt="" />
              <p className="pt-3 pe-2">المصداقية</p>
            </div>
          </div>

          {/* <p>
            التميز <br />
            الجودة
            <br />
            المصداقية
          </p> */}
        </div>
        <div className="vision">
          <div className="vision-title">
            <p className="title">رئيس مجلس الإدارة</p>
          </div>
          <p className="text">
            بما اننا نعيش في وطن يذخر بالتقدم والابداع وايمانا منا بان سبل
            النجاح تكمن في فهم وتطبيق المعرفة والأفكار الجديدة وتماشيا مع رؤية
            المملكة 2030 أردنا ان نكون جزء فعال ومساهم في تلك الرؤية وتبين لنا
            ان قطاع المقاولات والتشييد والبناء يعتبر من أحد ابرز دعائم التنمية
            الاقتصادية وبفضل الله قمنا بتأسيس شركتنا كشركة وطنية تساهم في
            الارتقاء في هذا المجال ووضع بصمة متميزة ساعدنا في ذلك الكوادر
            الماهرة ذات الخبرة والثقة الغالية من عملاءنا الكرام.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
