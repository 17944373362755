import React, { useState, useEffect, useRef } from "react";
import "./Header.css";
import { LuMenu } from "react-icons/lu";
import { AiOutlineClose } from "react-icons/ai";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import Menu from "./Menu";
import Logo from "../Assets/images/unionlogo.svg";

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsScrolled(scrollTop > 0.1);
    };

    const handleOutsideClick = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        setToggleMenu(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    document.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <div className={` ${isScrolled ? "scrolled" : "hero_section"}`}>
      <div className="navv  ">
        <div className="logo-div">
          <Link to="/">
            {" "}
            <img className="logo" src={Logo} alt="" />
          </Link>
        </div>

        <div className="menu" ref={menuRef}>
          <LuMenu
            className="menu_icon"
            onClick={() => {
              setToggleMenu(!toggleMenu);
            }}
          />
        </div>

        {toggleMenu && (
          <div className="menu_items">
            <AiOutlineClose
              className="close_icon"
              onClick={() => setToggleMenu(false)}
            />
            <Menu />
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
