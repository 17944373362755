import React, { useEffect, useState } from "react";
import "./ContactUs.css";
import Header from "../Home/Header";
import pic from "../Assets/images/jpg/67-a.jpg";
import Footer from "../Home/Footer";


const ContactUs = () => {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
      const handleScroll = () => {
        const scrollTop = window.scrollY;
        if (scrollTop > 0.1) {
          setIsScrolled(true);
        } else {
          setIsScrolled(false);
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);
  
  return (
    <div>
      <div className={` ${isScrolled ? "noback" : "back"}`}></div>
      <Header />
      <div className="classs">
        <img className="mainimg" src={pic}></img>
      </div>
      <div className="back2"></div>
      <div className="who-us container">
        <h1> إتـصـل بـنـا</h1>
      </div>
      <div className="break"></div>
      <Footer />
    </div>
  );
};

export default ContactUs;
