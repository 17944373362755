import React, { useEffect, useState } from 'react';
import "./Logos.css";
import axios from 'axios';
import Lgo from "../Assets/images/alzahedlogo.svg";
import Lgo1 from "../Assets/images/alwahalogo.svg";
import Lgo2 from "../Assets/images/salhiylogo.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Autoplay } from 'swiper/modules';


const Logos = () => {
    const [logosData, setLogosData] = useState([]);
     useEffect(() => {
       fetchLogosData();
     }, []);

     const fetchLogosData = async () => {
       await axios
         .get("https://back-end.uniondiamondco.com/api/logos")
         .then((res) => {
           setLogosData(res.data.data);
         });
       
  };
   var settings = {
     dots: true,
     infinite: true,
     slidesToShow: 4,
     slidesToScroll: 1,
     autoplay: false,
     speed: 3000,
     autoplaySpeed: 3000,
     Autoplay: true,
     cssEase: "linear",
     responsive: [
       {
         breakpoint: 1024,
         settings: {
           slidesToShow: 3,
           slidesToScroll: 1,
           infinite: true,
           dots: true,
         },
       },
       {
         breakpoint: 600,
         settings: {
           slidesToShow: 3,
           slidesToScroll: 1,
           initialSlide: 2,
         },
       },
       {
         breakpoint: 480,
         settings: {
           slidesToShow: 3,
           slidesToScroll: 1,
         },
       },
     ],
   };
  return (
    <div className="logos-container">
      {logosData.image && (
        <div className="mainContainer">
          <Slider {...settings}>
            {
              (logosData.image).map((logoo, indx) => (
                <div className="container">
                  <img src={logoo} />
                </div>
              ))}
          </Slider>
        </div>
      )}
    </div>
  );
}

export default Logos


//  {
//      logosData.map((logo, indx) => (
//        <div key={indx} className="logos-img-div">
//          <img className="logos-img" src={logo.image} />
//        </div>
//      