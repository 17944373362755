import React, { useEffect, useState } from "react";
import "./Home.css";
import Map from "./Map";
import Proj from "./Proj";
import Footer from "./Footer";
import Header from "./Header";
import Hero from "./Hero";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import Logos from "./Logos";
import ic1 from "../Assets/images/available_new.svg";
import ic2 from "../Assets/images/sold_new-1.svg";
import ic3 from "../Assets/images/soon_new.svg";
import ic4 from "../Assets/images/soon_new copy.svg";


const Home = () => {
  const [websiteData, setWebsiteData] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchWebsiteData();
  }, []);

  const fetchWebsiteData = async () => {
    await axios
      .get("https://back-end.uniondiamondco.com/api/website-data")
      .then((res) => {
        setWebsiteData(res.data.data);
      });
    setLoading(true);
    console.log(websiteData.latitude);
  };
  return (
    <div className="">
      {loading ? (
        <div>
          <Header />
          <Hero />
          <Proj />

          <Logos />

          <p className="mapp-title mb-5">مواقع المشاريع</p>
          <div className="pins d-flex flex-row justify-content-center mb-3">
            <div className="d-flex flex-column align-items-center ">
              <img src={ic1} />
              <p className="pins-name">متاح </p>
            </div>
            <div className="d-flex flex-column align-items-center">
              <img src={ic2} />
              <p className="pins-name">قريبا </p>
            </div>
            <div className="d-flex flex-column align-items-center">
              <img src={ic3} />
              <p className="pins-name">مباع </p>
            </div>
            <div className="d-flex flex-column align-items-center">
              <img src={ic4} />
              <p className="pins-name">جاري الإنشاء </p>
            </div>
            
          </div>

          <div className="homemap">
            <Map  />
          </div>

          <Footer />
        </div>
      ) : (
        <div className="overlay">
          <Spinner animation="grow" variant="info" />
          <Spinner animation="grow" variant="secondary" />
          <Spinner animation="grow" variant="dark" />
        </div>
      )}
    </div>
  );
};

export default Home;
