import React, { useEffect, useState } from "react";
import "./Hero.css";
import "bootstrap/dist/css/bootstrap.min.css";
import pic from "../Assets/images/jpg/67-a.jpg";
import pic2 from "../Assets/images/jpg/73-b2.jpg";
import pic3 from "../Assets/images/jpg/02. Exterior Day 2.jpg";
import pic4 from "../Assets/images/jpg/03. Exterior Evening.jpg";
import pic5 from "../Assets/images/jpg/04. Exterior Night.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";


import homeicon from "../Assets/images/web_2024-24.svg";
// import "./styles.css";
import { Navigation, Pagination, Mousewheel, Keyboard, Autoplay ,EffectFade} from "swiper/modules";
import axios from "axios";

  


const Hero = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [websiteData, setWebsiteData] = useState("");
  const [slidereData, setSliderData] = useState([]);
  


  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 0.1) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
   useEffect(() => {
     fetchWebsiteData();
     fetchSliderData();
   }, []);

   const fetchWebsiteData = async () => {
     await axios
       .get("https://back-end.uniondiamondco.com/api/website-data")
       .then((res) => {
         setWebsiteData(res.data.data);
       });
   };
   const fetchSliderData = async () => {
     await axios
       .get("https://back-end.uniondiamondco.com/api/slider")
       .then((res) => {
         setSliderData(res.data);
         console.log(slidereData);
       });
   };
  return (
    <div className="hero ">
      <div className={` ${isScrolled ? "noback" : "back"}`}></div>
      <Swiper
        cssMode={true}
        navigation={true}
        pagination={{ clickable: true }}
        mousewheel={true}
        keyboard={true}
        loop={true}
        speed={2000}
        autoplay={{
          delay: 10000,
          disableOnInteraction: true,
        }}
        modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay]}
        className="mySwiperr"
      >
        {(slidereData.length>0) &&
          slidereData.map((sliderimage, indx) => (
            <SwiperSlide key={sliderimage.id}>
              <img className="pc" src={sliderimage.image} />
            </SwiperSlide>
          ))}
      </Swiper>
      <div className=" counter mb-3 mt-3  ">
        <div className=" container content d-flex flex-row justify-content-between">
          <div className="right d-flex flex-row align-items-center">
            <img className="home-icon  " src={homeicon} alt="" />

            <span className="num ">{websiteData.projects_count}</span>
            <span className="txt">مشروع</span>
          </div>
          <div className="left d-flex flex-row align-items-center">
            <span className="num ">{websiteData.units_count}</span>
            <span className="txt ">وحدة سكنية</span>
          </div>
        </div>
      </div>
      <div className="newest mt-3 mb-4">
        <p className="top">أحدث المشاريع</p>
        <p className="bottom"> أحدث مشاريع إتحاد الماسة</p>
      </div>
    </div>
  );
};

export default Hero;
