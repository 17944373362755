import React, { useEffect, useState } from "react";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";


import "./Map.css";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import ic1 from "../Assets/images/available_new.svg";
import ic2 from "../Assets/images/sold_new-1.svg";
import ic3 from "../Assets/images/soon_new.svg";
import ic4 from "../Assets/images/soon_new copy.svg";


const iconUrls = {
  1: ic1,
  2: ic2,
  3: ic3,
  4: ic4,
};

const mapStyles = [
  {
    featureType: "all",
    elementType: "all",
    stylers: [{ saturation: -100 }, { lightness: 20 }],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [{ lightness: 50 }, { visibility: "simplified" }],
  },
  {
    featureType: "road",
    elementType: "labels",
    stylers: [{ visibility: "simplified" }],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [{ lightness: 30 }],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [{ color: "#f0f0f0" }], // Light gray color for water
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "transit",
    elementType: "all",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "administrative",
    elementType: "labels",
    stylers: [{ visibility: "simplified" }],
  },
];

const Map = () => {
  const [selected, setSelected] = useState(null);
  const [locations, setLocations] = useState([]);
  const [center, setCenter] = useState({ lat: 24.7136, lng: 46.6753 }); // Default center for Riyadh
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    fetchLocations();
  
  }, []);
  const containerStyle = {
    width: "100%",
    height: "100%",
  };


  const fetchLocations = async () => {
    try {
      const response = await axios.get(
        "https://back-end.uniondiamondco.com/api/map-info"
      );
      const data = response.data.data.map((location) => ({
        ...location,
        latitude: parseFloat(location.latitude),
        longitude: parseFloat(location.longitude),
        status: parseInt(location.status), // Ensure status is an integer
      }));
      setLocations(data);
      calculateCenter(data);
      console.log("Fetched locations:", data); // Log fetched data to inspect its structure
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
    setLoading(true);

  };

  const calculateCenter = (locations) => {
    if (locations.length === 0) return;

    const sumLat = locations.reduce((sum, loc) => sum + loc.latitude, 0);
    const sumLng = locations.reduce((sum, loc) => sum + loc.longitude, 0);
    const avgLat = sumLat / locations.length;
    const avgLng = sumLng / locations.length;

    setCenter({ lat: avgLat, lng: avgLng });
  };

  useEffect(() => {
    console.log("Updated locations state:", locations);
    // Log locations to inspect its structure
  }, [locations]);

  return (
    <div className="map-container">
      <LoadScript googleMapsApiKey="AIzaSyDQ-KjfF-16mlmAqTIC5TiwQ3wVn5ZcabE">
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={10}
          options={{
            styles: mapStyles,
            mapTypeControl: false,
            streetViewControl: false,
            disableDefaultUI: true,
          }}
        >
          {loading ? (
            locations.map((location) => (
              <Marker
                key={location.id}
                position={{ lat: location.latitude, lng: location.longitude }}
                icon={iconUrls[location.status]} // Map integer status to icon URL
                onClick={() => setSelected(location)}
              />
            ))
          ) : (
            <div className="overlay">
              <Spinner animation="grow" variant="info" />
              <Spinner animation="grow" variant="secondary" />
              <Spinner animation="grow" variant="dark" />
            </div> // Show a message if no markers are available
          )}
          {selected && (
            <InfoWindow
              position={{ lat: selected.latitude, lng: selected.longitude }}
              onCloseClick={() => setSelected(null)}
            >
              <Link to={`/projectdetails/${selected.id}`}>
                <div style={{ width: "200px" }}>
                  <p
                    className="pin-desc-title"
                    style={{ margin: "0", fontWeight: "bold" }}
                  >
                    {selected.name}
                  </p>
                  <img
                    src={selected.main_image}
                    alt="Location"
                    style={{ width: "100%", height: "auto", marginTop: "5px" }}
                  />
                </div>
              </Link>
            </InfoWindow>
          )}
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default Map;
