import React, { useEffect, useRef, useState } from 'react';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "./Footer.css"
import "bootstrap/dist/css/bootstrap.min.css";
import footerLogo from "../Assets/images/web_2024-39.svg";
import leftArrow from "../Assets/images/web_2024-27.svg";
import instagram from "../Assets/images/web_2024-22.svg";
import whatsApp from "../Assets/images/web_2024-23.svg";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import Alert from "react-bootstrap/Alert";
import Toast from "react-bootstrap/Toast";
import Home from './Home';
import ProjectsDetails from '../projectDetails/ProjectsDetails';
import phoneIcon from "../Assets/images/web_2024-29.svg";
import locationIcon from "../Assets/images/web_2024-28.svg";
import emailIcon from "../Assets/images/web_2024-30.svg";




const Footer = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [popupMsg, setPopupMsg] = useState("");
  const [show, setShow] = useState(false);
  const [loaded , setLoaded] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const formbtnn = useRef(null);
    const [websiteData, setWebsiteData] = useState("");


  const toggleToast = () => setShowToast(!showToast);
   const getLocationUrl = (latitude,longitude) => {
     return `https://www.google.com/maps?q=${latitude},${longitude}`;
   };

  

  const sendMessage = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", name);
    formData.append("phone", phone);
    formData.append("message", message);

    await axios
      .post("https://back-end.uniondiamondco.com/api/contact", formData)
      .then(({ data }) => {
           setName("");
           setPhone("");
           setMessage("");
        setPopupMsg(
          ` تلقينا استفسارك يا ${name} وسيتم التواصل معك في أقرب وقت ! `
        );
      });
     
    toggleToast();
    setLoaded(true);
    if (formbtnn.current) {
       
       formbtnn.current.blur();
    }
  
  };
  
  useEffect(() => {
    fetchWebsiteData();
  }, []);

  const fetchWebsiteData = async () => {
    await axios
      .get("https://back-end.uniondiamondco.com/api/website-data")
      .then((res) => {
        setWebsiteData(res.data.data);
      });
  };


 
  return (
    <div className="footer  ">
      <div className="container">
        <div className="part1 mb-5  ">
          <div className="  d-flex flex-row align-items-center">
            <p className="book ">إحـجـــز الأن</p>
            <img className="leftarrow" src={leftArrow} alt="" />
          </div>
          <div className=" d-flex flex-row ">
            {/* <p className="nums  onee">0533111127</p> */}
            <a
              className="contact d-flex flex-row"
              href="tel:{websiteData.res_num1}"
              target="_blank"
            >
              <span className="nums onee ms-2">{websiteData.res_num2}</span>
              {/* <span className="nums ms-2"></span> */}
            </a>
            <a
              className="contact d-flex flex-row"
              href="tel:{websiteData.res_num}"
              target="_blank"
            >
              <span className="nums ms-2">{websiteData.res_num1}</span>
            </a>
            {/* <p className="nums ">0533111127</p> */}
          </div>
          <div className="d-flex flex-row">
            <a
              className="ms-3"
              href={`http://wa.me/${websiteData.whats_app_num}`}
              target="_blank"
            >
              <img className="whatsapp    " src={whatsApp} alt="" />
            </a>
            <a className="" href={websiteData.instagram_link} target="_blank">
              <img className="instagram" src={instagram} alt="" />
            </a>
          </div>
        </div>
        <div className=" part2  mb-5">
          <img className="footerlogo" src={footerLogo} alt="" />
          <Form onSubmit={sendMessage} className=" pt-5">
            <Form.Control
              className="fc name"
              size="lg"
              type="text"
              value={name}
              required
              onChange={(e) => {
                setName(e.target.value);
              }}
              placeholder="الإسم"
            />
            <Form.Control
              size="lg"
              className="fc numb "
              type="text"
              placeholder="جوال الرقم "
              value={phone}
              required
              onChange={(e) => {
                setPhone(e.target.value);
              }}
            />
            <Form.Control
              size="lg"
              className="fc ask"
              type="text"
              placeholder="استعلم عن "
              value={message}
              required
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            />

            <Button className="formbtn" ref={formbtnn} type="submit">
              إرسل
            </Button>
            <div className="toast-div">
              <Toast
                bg="success"
                show={showToast}
                onClose={toggleToast}
                delay={3000}
                autohide
                className="toast"
              >
                <Toast.Header className="bg-success"></Toast.Header>
                <Toast.Body>{popupMsg}</Toast.Body>
              </Toast>
            </div>
          </Form>
        </div>

        <div className="part3  pb-2 ">
          <p className="part3-title">عن إتحاد الماسة</p>
          <p className="part3-parag ">
            اتحاد الماسة للمقاولات تم تأسيسها في عام 2004 لتكون ضمن الشركات
            الوطنية الرائدة في مجال التطوير العقاري والاعمال الانشائية بمعايير
            جودة عالية وتقنيات حديثة تلبى كافة رغبات وطلبات السوق المحلى
          </p>
        </div>
        <div className="part4 container d-flex  ">
          <a
            className="contactt insta"
            href={websiteData.instagram_link}
            target="_blank"
          >
            <span className="contact-txt"> uniondiamondco</span>
            <img className="contact-icon  " src={instagram} alt="" />
          </a>
          <a
            className="contactt"
            href={`mailto:${websiteData.email}`}
            target="_blank"
          >
            <span className="contact-txt"> {websiteData.email}</span>
            <img className="contact-icon " src={emailIcon} alt="" />
          </a>
          <a
            className="contactt"
            href={`tel:${websiteData.footer_num2}`}
            target="_blank"
          >
            <span className="contact-txt"> {websiteData.footer_num2}</span>
            <img className="contact-icon " src={phoneIcon} alt="" />
          </a>
          <a
            className="contactt"
            href={`tel:${websiteData.footer_num1}`}
            target="_blank"
          >
            <span className="contact-txt"> {websiteData.footer_num1}</span>
            <img className="contact-icon " src={phoneIcon} alt="" />
          </a>
          <div className="contactt">
            <a
              href={getLocationUrl(websiteData.latitude ,websiteData.longitude )}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="conntact-txt">المملكة العربية السعودية جدة</span>
            </a>
            <img className="contact-icon " src={locationIcon} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;