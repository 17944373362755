import React from "react";
import "./App.css";
import Home from "./Home/Home";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import About from "./Menu/About";
import ContactUs from "./Menu/ContactUs";
import Photos from "./Menu/Photos";
import Projects from "./Menu/Projects";
import Stages from "./Menu/Stages";
import ProjectsDetails from "./projectDetails/ProjectsDetails";


function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/"  element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/photos" element={<Photos />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/stages" element={<Stages />} />
          <Route path="/projectdetails/:id" element={<ProjectsDetails />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
