import React, { useEffect, useState } from 'react'
import Header from "../Home/Header";
import pic from "../Assets/images/jpg/67-a.jpg";
import badge from "../Assets/images/web_2024-25.svg";
import "./Projects.css"
import Footer from '../Home/Footer';
import Proj from '../Home/Proj';
import axios from 'axios';


const Projects = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [websiteData, setWebsiteData] = useState("");
  

    const fetchWebsiteData = async () => {
      await axios
        .get("https://back-end.uniondiamondco.com/api/website-data")
        .then((res) => {
          setWebsiteData(res.data.data);
          console.log(websiteData);
        });
    };

  useEffect(() => {
      fetchWebsiteData();
      const handleScroll = () => {
        const scrollTop = window.scrollY;
        if (scrollTop > 0.1) {
          setIsScrolled(true);
        } else {
          setIsScrolled(false);
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);
  return (
    <div>
      <div className={` ${isScrolled ? "noback" : "back"}`}></div>
      <Header />

      <div className="classs">
        <img className="mainimg" src={websiteData.projects_image}></img>
      </div>
      <div className="back2"></div>
      <div className="who-us container">
        <h1> مشـاريعـنـا</h1>
      </div>
      <div className="break mb-5"></div>

      <Proj />
      <Footer />
    </div>
  );
}

export default Projects